import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"

import { getUserLanguage } from "../utils/utils"
import { Helmet } from "react-helmet";

function MainRouter() {
  useEffect(() => {
    const userLanguage = getUserLanguage();
    navigate(`/${userLanguage}/`)
  });
  return null;
}

export default MainRouter

MainRouter.propTypes = {
}
